import React from "react"
import PropTypes from "prop-types"
import PATHS from "static/constants/paths.js"
import Link from "components/elements/link"
import "./customBreadcrumb.scss"

const CustomBreadcrumb = ({
  mainNavigation,
  next,
  prev,
  routePrefix = "/",
}) => (
  <div className="CustomBreadcrumb">
    <div className="container d-flex justify-content-between p-0">
      {prev && (
        <div className="next d-flex align-items-center">
          <Link to={`${routePrefix}${prev.route}`}>
            <>
              <img
                alt="left-arrow.svg"
                height="22"
                src="/images/left-arrow-white.svg"
                width="32"
              />
              <span className="lable">{prev.label}</span>
            </>
          </Link>
        </div>
      )}
      <div className="mainNavigation">
        <Link to={mainNavigation.route}>
          <img alt="mainNavigation" src={mainNavigation.icon} />
        </Link>
      </div>
      {next ? (
        <div className="prev d-flex align-items-center">
          <Link to={`${routePrefix}${next.route}`}>
            <>
              <span className="lable">{next.label}</span>
              <img
                alt="right-arrow"
                height="22"
                src="/images/right-arrow-white.svg"
                width="32"
              />
            </>
          </Link>
        </div>
      ) : (
        <div className="prev d-flex align-items-center">
          <Link to={PATHS.MAKE_AN_APPOINTMENT}>
            <>
              <span className="lable">Make An Appointment</span>
              <img
                alt="right-arrow.svg"
                height="22"
                src="/images/right-arrow-white.svg"
                width="32"
              />
            </>
          </Link>
        </div>
      )}
    </div>
  </div>
)

export const CustomBreadcrumbProps = {
  mainNavigation: PropTypes.shape({
    icon: PropTypes.string,
    route: PropTypes.string,
  }),
  next: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  prev: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  routePrefix: PropTypes.string,
}

CustomBreadcrumb.propTypes = CustomBreadcrumbProps

export default CustomBreadcrumb
