import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template, { TreatmentTemplateDetailsProps } from "./template"

const TemplateContainer = ({
  data: { footer, header, template, treatment, treatments },
}) => {
  const treatmentIndex = treatments.nodes.findIndex(
    item => item.route === treatment.slug
  )
  const prevTreatment =
    treatmentIndex - 1 < 0 ? null : treatments.nodes[treatmentIndex - 1]
  const nextTreatment =
    treatmentIndex + 1 >= treatments.nodes.length
      ? null
      : treatments.nodes[treatmentIndex + 1]

  return (
    <>
      <Seo
        {...treatment.meta}
        slug={`${template.slugPrefix}/${treatment.slug}`}
      />
      <Header {...header} />
      <Template
        {...template}
        next={nextTreatment}
        prev={prevTreatment}
        treatment={treatment}
      />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...TreatmentTemplateDetailsProps,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(Template.props),
    treatment: PropTypes.shape(TemplateContainer.props),
    treatments: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          route: PropTypes.string,
        })
      ),
    }),
  }),
}

export default TemplateContainer

export const query = graphql`
  query Treatment($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    treatment: contentfulTreatment(slug: { eq: $slug }) {
      name
      slug
      style
      sections {
        __typename
        ... on ContentfulSectionWrapper {
          __typename
          backgroundStyle
          title
          backgroundImage {
            title
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          wrapperSections: sections {
            __typename
            ... on ContentfulSectionQuote {
              __typename
              name
              quote {
                id
                quote
              }
            }
            ... on ContentfulCallToAction {
              __typename
              buttonText
              id
              name
              style
              action {
                __typename
                ... on ContentfulActionRedirect {
                  __typename
                  id
                  openInANewTab
                  url
                }
              }
              buttonImage {
                title
                file {
                  url
                }
              }
            }
            ... on ContentfulSectionListOfAccordionCards {
              __typename
              id
              style
              cards {
                headline
                id
                subheadline
                content {
                  content
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
            ... on ContentfulSectionQuote {
              __typename
              id
              name
              quote {
                quote
              }
            }
            ... on ContentfulHeadline {
              __typename
              id
              headline
            }
            ... on ContentfulSectionListOfActions {
              __typename
              id
              actions {
                buttonText
                style
                action {
                  __typename
                  ... on ContentfulActionRedirect {
                    __typename
                    openInANewTab
                    url
                  }
                }
              }
            }
            ... on ContentfulSectionWrapper {
              __typename
              backgroundStyle
              title
              wrapperSections: sections {
                __typename
                ... on ContentfulSectionSideBySide {
                  __typename
                  name
                  style
                  sections {
                    __typename
                    ... on ContentfulSectionForm {
                      __typename
                      formTitleColor
                      subject
                      title
                      description {
                        childMarkdownRemark {
                          html
                        }
                      }
                      disclaimer {
                        childMarkdownRemark {
                          html
                        }
                      }
                      inputFields {
                        __typename
                        ... on ContentfulFormInputFieldSelect {
                          __typename
                          id
                          label
                          options
                          required
                          source
                        }
                        ... on ContentfulFormInputFieldText {
                          __typename
                          label
                          maximumCount
                          placeholder
                          required
                          type
                        }
                        ... on ContentfulFormInputFieldSideBySide {
                          __typename
                          label
                          inputFields {
                            __typename
                            ... on ContentfulFormInputFieldText {
                              __typename
                              id
                              label
                              maximumCount
                              placeholder
                              required
                              type
                            }
                          }
                        }
                        ... on ContentfulFormInputFieldHidden {
                          __typename
                          class
                          dataFieldName
                          fieldId
                          name
                          title
                        }
                      }
                      submitAction {
                        buttonText
                        style
                        action {
                          __typename
                          ... on ContentfulActionFormSubmission {
                            __typename
                            action
                            title
                          }
                        }
                      }
                    }
                    ... on ContentfulSectionRichText {
                      __typename
                      alignment
                      details {
                        json
                      }
                    }
                  }
                }
                ... on ContentfulSectionRichText {
                  __typename
                  alignment
                  details {
                    json
                  }
                }
              }
            }
            ... on ContentfulSectionSideBySide {
              __typename
              name
              style
              sections {
                __typename
                ... on ContentfulSectionForm {
                  __typename
                  formTitleColor
                  subject
                  title
                  description {
                    content: description
                  }
                  disclaimer {
                    childMarkdownRemark {
                      html
                    }
                  }
                  inputFields {
                    __typename
                    ... on ContentfulFormInputFieldSelect {
                      __typename
                      id
                      label
                      options
                      required
                      source
                    }
                    ... on ContentfulFormInputFieldText {
                      __typename
                      label
                      maximumCount
                      placeholder
                      required
                      type
                    }
                    ... on ContentfulFormInputFieldSideBySide {
                      __typename
                      label
                      inputFields {
                        __typename
                        ... on ContentfulFormInputFieldText {
                          __typename
                          id
                          label
                          maximumCount
                          placeholder
                          required
                          type
                        }
                      }
                    }
                    ... on ContentfulFormInputFieldHidden {
                      __typename
                      class
                      dataFieldName
                      fieldId
                      name
                      title
                    }
                  }
                  submitAction {
                    buttonText
                    style
                    action {
                      __typename
                      ... on ContentfulActionFormSubmission {
                        __typename
                        action
                        title
                      }
                    }
                  }
                }
                ... on ContentfulSectionRichText {
                  __typename
                  alignment
                  details {
                    json
                  }
                }
              }
            }
            ... on ContentfulSectionRichText {
              __typename
              alignment
              details {
                json
              }
            }
            ... on ContentfulSectionText {
              __typename
              headline
              style
              title
              body {
                body
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulVideoWrapper {
              __typename
              title
              url
            }
            ... on ContentfulSectionList {
              __typename
              listOptions
              numberOfColumns
              style
              title
              icon {
                title
                file {
                  url
                }
              }
            }
            ... on ContentfulParagraphsList {
              __typename
              layout
              title
              paragraphs {
                alignment
                title
                description {
                  description
                }
                icon {
                  title
                  file {
                    url
                  }
                }
              }
              actions {
                buttonText
                style
                action {
                  __typename
                  ... on ContentfulActionRedirect {
                    __typename
                    openInANewTab
                    title
                    url
                  }
                }
              }
            }
          }
        }
        ... on ContentfulSectionSplitContent {
          __typename
          backgroundColor
          reverse
          image {
            image {
              alt: description
              title
              file {
                url
              }
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionFaq {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          questions {
            title: question
            text: answer {
              json
            }
          }
        }
        ... on ContentfulSectionRichText {
          __typename
          alignment
          details {
            json
          }
          web_page {
            title
          }
        }
      }
      description {
        description
      }
      details {
        json
      }
      icon {
        title
        file {
          url
        }
      }
      video {
        backgroundColor
        style
        subtitle
        title
        actions {
          __typename
          buttonText
          style
          buttonImage {
            title
            file {
              url
            }
          }
          action {
            __typename
            ... on ContentfulActionRedirect {
              __typename
              title
              url
            }
          }
        }
        backgroundImage {
          title
          file {
            url
          }
        }
        backgroundVideo {
          title
          file {
            url
          }
        }
        description {
          description
        }
        photo {
          file {
            url
          }
        }
        video {
          title
          url
        }
        web_page {
          slug
          title
        }
        navigator {
          buttonText
          style
          action {
            __typename
            ... on ContentfulActionNavigate {
              __typename
              section {
                __typename
                ... on ContentfulSectionText {
                  __typename
                  title
                }
              }
            }
          }
          buttonImage {
            title
            fluid(maxWidth: 200, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      meta: seoMetadata {
        canonical
        keywords
        title
        type
        abstract {
          abstract
        }
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
    treatments: allContentfulTreatment {
      nodes {
        label: name
        route: slug
      }
    }
    template: contentfulWebPageTemplate(model: { eq: "Treatment" }) {
      slugPrefix
      title
      sections {
        __typename
        ... on ContentfulTemplateSection {
          __typename
          title
        }
      }
    }
  }
`
