import React from "react"
import PropTypes from "prop-types"
import CustomBreadcrumb from "components/sections/customBreadcrumb"
import "./treatmentDetails.scss"
import Wrapper from "components/sections/wrapper"

const TreatmentDetails = ({ next, prev, slugPrefix, treatment }) => {
  return (
    <div id="TreatmentDetails">
      <div className="top-breadcrumb">
        <CustomBreadcrumb
          mainNavigation={{
            icon: "/images/home-white.svg",
            route: "/",
          }}
          next={next}
          prev={prev}
          routePrefix={`/${slugPrefix}/`}
        />
      </div>
      <Wrapper wrapperSections={treatment.sections} />
      <CustomBreadcrumb
        mainNavigation={{
          icon: "/images/home-white.svg",
          route: "/treatments",
        }}
        next={next}
        prev={prev}
        routePrefix={`/${slugPrefix}/`}
      />
    </div>
  )
}

TreatmentDetails.props = {
  CTA: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  details: PropTypes.shape({
    json: PropTypes.object,
  }),
  icon: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  name: PropTypes.string,
  slug: PropTypes.string,
  style: PropTypes.string,
}

TreatmentDetails.propTypes = {
  next: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  prev: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  slugPrefix: PropTypes.string,
  treatment: PropTypes.shape(TreatmentDetails.props),
}

export default TreatmentDetails
