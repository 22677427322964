import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import TreatmentDetails from "views/treatmentDetails"
import HeroImage from "components/sections/heroImage"
import { CallToActionProps } from "components/blocks/callToAction"

const TreatmentTemplate = ({ next, prev, sections, slugPrefix, treatment }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case SECTIONS.TemplateDetailsSection:
        return (
          <TreatmentDetails
            key={i}
            next={next}
            prev={prev}
            slugPrefix={slugPrefix}
            treatment={treatment}
          />
        )

      case SECTIONS.SectionHeroPhoto:
        return <HeroImage {...section} key={i} />

      default:
        return null
    }
  })

const SectionHeroPhotoProps = {
  __typename: PropTypes.string,
  action: PropTypes.shape(CallToActionProps),
  backgroundImage: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  headlineColor: PropTypes.string,
  headlineHero: PropTypes.shape({
    headlineHero: PropTypes.string,
  }),
  style: PropTypes.string,
}

const TemplateSectionProps = {
  __typename: PropTypes.string,
  additionalSettings: PropTypes.shape({
    enableDirectionsCTA: PropTypes.bool,
  }),
  title: PropTypes.string,
}

export const TreatmentTemplateDetailsProps = TreatmentDetails.props

TreatmentTemplate.props = {
  model: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(SectionHeroPhotoProps),
      PropTypes.shape(TemplateSectionProps),
    ])
  ),
  slugPrefix: PropTypes.string,
  title: PropTypes.string,
}

TreatmentTemplate.propTypes = {
  ...TreatmentTemplate.props,
  next: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  prev: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
  }),
  treatment: PropTypes.shape(TreatmentDetails.props),
}

export default TreatmentTemplate
